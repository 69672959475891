<template>
  <div class="promo-page">
    <div class="promo-page-content">
      <Meetings></Meetings>
    </div>
  </div>
</template>

<script>
import Meetings from '@/_modules/meetings/components/meetings/meetings.vue';

export default {
  name: 'promo-page-calendar',
  components: {
    Meetings
  },
};
</script>

<style scoped lang="scss">

.promo-page {
  padding: #{$event-head-panel-height + $promo-page-padding-top + 22px} 0 7rem;
}

.main {
  position: relative;

  &-edituserpromo {
    height: auto !important;
  }
}

.view-block {
  position: relative;
  padding: 66px 90px 7rem;
}

.right-indent {
  margin-right: 16px;
}

</style>
